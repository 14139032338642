<template>
  <div>
    <div v-if="!show_step">
      <!-- ------------------------------PASO 1------------------------------ -->
      <section v-if="step == 1">
        <div class="step-grid mb-5">
          <div class="step-icon-border first-load-border mr-5">
            <b-icon-list-ul
              id="step1"
              class="step-icon-info first-load-icon"
            ></b-icon-list-ul>
            <p class="step-caption text-success">
              Paso 1: Detalles del
              {{ $getVisibleNames("evaluations2.test", false, "Instrumento") }}
            </p>
          </div>
          <div class="step-icon-border mx-5">
            <b-icon-people-fill class="step-icon"></b-icon-people-fill>
            <p class="step-caption">Paso 2: Asignar Evaluado(s)</p>
          </div>
          <div class="step-icon-border ml-5">
            <b-icon-person-check-fill
              class="step-icon"
            ></b-icon-person-check-fill>
            <p class="step-caption">Paso 3: Asignar Evaluador(es)</p>
          </div>
        </div>
        <span class="progress first-load"></span>

        <div class="container-group">
          <!-- Título -->
          <b-form-group
            label="Título del Instrumento"
            label-for="input-name"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <b-form-input
              id="input-name"
              v-model="$v.instrument.title.$model"
              :disabled="locked_instrument || !allows_crud"
              :state="validateState('title')"
              aria-describedby="input-name-feedback"
              size="sm"
            ></b-form-input>
            <b-form-invalid-feedback id="input-name-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>

          <!-- Descripción -->
          <b-form-group
            v-if="institution && institution.internal_use_id != 'duoc_uc'"
            label="Descripción"
            label-for="input-description"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <template v-if="!locked_instrument && allows_crud">
              <div
                v-b-tooltip.v-secondary="'Editar Descripción'"
                class="instrument-form-description rich-text-content mb-2"
                v-html="$v.instrument.description.$model"
                @click="
                  $bvModal.show(
                    `edit-instrument-description-modal-${instrument.id}`
                  )
                "
              ></div>
            </template>
            <template v-else>
              <div
                class="instrument-form-description-locked rich-text-content mb-2"
                v-html="$v.instrument.description.$model"
              ></div>
            </template>
            <b-modal
              :id="`edit-instrument-description-modal-${instrument.id}`"
              title="Editar Descripción de la Evaluación"
              size="lg"
              hide-footer
            >
              <NewRichTextEditor
                :Object="instrument"
                :Text="instrument.description"
                @save="patchInstrument"
                @close="
                  $bvModal.hide(
                    `edit-instrument-description-modal-${instrument.id}`
                  )
                "
              ></NewRichTextEditor>
            </b-modal>
            <b-form-invalid-feedback id="input-description-feedback"
              >Este campo es opcional.</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group
            :label="
              $getVisibleNames(
                'teaching.evaluationsituation',
                false,
                'Situación Evaluativa'
              )
            "
            label-for="select-evaluation_situation"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <b-form-select
              id="select-evaluation_situation"
              v-model="$v.instrument.evaluation_situation.$model"
              :options="evaluation_situations"
              :disabled="locked_instrument || !allows_crud"
              value-field="id"
              text-field="name"
              :state="validateState('evaluation_situation')"
              aria-describedby="select-evaluation_situation-feedback"
              size="sm"
              class="mt-1"
            >
              <template #first>
                <b-form-select-option :value="null"
                  >N/A
                </b-form-select-option></template
              >
            </b-form-select>
            <b-form-invalid-feedback id="select-evaluation_situation-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
          <!-- Tipo => Agente Evaluativo -->
          <b-form-group
            label="Agente Evaluativo"
            label-for="select-evaluative_agent"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <b-form-select
              id="select-evaluative_agent"
              v-model="$v.instrument_type.evaluative_agent.$model"
              :disabled="locked_instrument || !allows_crud"
              :state="validateStateType('evaluative_agent')"
              :options="instrument_types"
              value-field="id"
              text-field="name"
              aria-describedby="select-evaluative_agent-feedback"
              size="sm"
            ></b-form-select>
            <b-form-invalid-feedback id="select-evaluative_agent-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group
            v-if="
              institution &&
              institution.internal_use_id != 'duoc_uc' &&
              evaluativeAgent &&
              (evaluativeAgent.internal_use_id ==
                'duoc_autoevaluacion_evaluative_agent' ||
                $equals(evaluativeAgent.name, 'Autoevaluación'))
            "
            label-for="input-student_can_auto_evaluate"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <template #label>
              <div class="block-title-container">
                <div>Permitir realizar Autoevaluación</div>
                <div class="ml-2">
                  <InfoTooltip
                    :tooltip_text="`Esta opción le permite al Evaluado responder la rúbrica.`"
                  ></InfoTooltip>
                </div>
              </div>
            </template>
            <b-form-checkbox
              class="mt-2"
              v-model="instrument.student_can_auto_evaluate"
              size="md"
              :disabled="!allows_crud"
            >
            </b-form-checkbox>
          </b-form-group>
          <!-- Ponderación -->
          <b-form-group
            label="Ponderación"
            label-for="input-weighing"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <b-form-input
              id="input-weighing"
              v-model="$v.instrument.weighing.$model"
              :disabled="locked_instrument || !allows_crud"
              :state="validateState('weighing')"
              type="number"
              aria-describedby="input-weighing-feedback"
              size="sm"
            ></b-form-input>
            <div class="weighing-aviable">
              Ponderación disponible:
              <span
                :class="{
                  'colorup-weighing-available': maxWeighingTest > 0,
                  'colordown-weighing-available': maxWeighingTest <= 0,
                }"
                >{{ maxWeighingTest > 0 ? maxWeighingTest : 0 }}%</span
              >
            </div>
            <b-form-invalid-feedback id="input-weighing-feedback">
              <div>
                Este campo es obligatorio, debe ser mayor o igual que 0
                <span v-if="instrument.weighing && instrument.weighing > 0">
                  y no puede superar
                  {{
                    parseFloat(maxWeighingTest) +
                    parseFloat(instrument.weighing)
                  }}%.
                </span>
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- Puntaje -->
          <b-form-group
            label="Puntaje"
            label-for="input-max_score"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <b-form-input
              id="input-max_score"
              v-model="$v.instrument.max_score.$model"
              :disabled="locked_instrument || !allows_crud"
              :state="validateState('max_score')"
              type="number"
              aria-describedby="input-max_score-feedback"
              size="sm"
            ></b-form-input>
            <b-form-invalid-feedback id="input-max_score-feedback"
              >Este campo es obligatorio y debe ser mayor de
              0.</b-form-invalid-feedback
            >
          </b-form-group>
          <!-- Fecha de Inicio -->
          <b-form-group
            label="Fecha de Inicio"
            label-for="input-start_date"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <template>
              <div class="d-flex">
                <div class="w-100">
                  <b-form-datepicker
                    id="input-start_date"
                    v-model="start_date"
                    :disabled="!allows_crud"
                    :state="validateState('start_time')"
                    placeholder="Definir fecha de inicio"
                    aria-describedby="input-start_date-feedback"
                    size="sm"
                    class="mt-1"
                  ></b-form-datepicker>
                  <b-form-invalid-feedback id="input-start_date-feedback"
                    >Debe ingresar una fecha de inicio
                    válida.</b-form-invalid-feedback
                  >
                </div>
                <b-button
                  v-if="allows_crud"
                  class="mt-1 ml-2 p-1 btn-clear"
                  variant="danger"
                  size="sm"
                  @click="clearStartTime()"
                >
                  <b-icon-trash></b-icon-trash>
                </b-button>
              </div>
            </template>
          </b-form-group>

          <!-- Hora de Inicio -->
          <b-form-group
            label="Hora de Inicio"
            label-for="input-start_time"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <template>
              <div>
                <b-form-timepicker
                  id="input-start_time"
                  v-model="start_time"
                  :disabled="!allows_crud"
                  :state="validateStateStartTime()"
                  class="mt-1 mb-1 start-time-input"
                  placeholder="Definir hora de inicio"
                  aria-describedby="input-start_time-feedback"
                  size="sm"
                  locale="en"
                ></b-form-timepicker>
                <b-form-invalid-feedback id="input-start_time-feedback"
                  >Debe ingresar la hora de inicio.</b-form-invalid-feedback
                >
              </div>
            </template>
          </b-form-group>
          <!-- Fecha de Entrega -->
          <b-form-group
            label="Fecha de Entrega"
            label-for="input-end_date"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <template>
              <div class="d-flex">
                <div class="w-100">
                  <b-form-datepicker
                    id="input-end_date"
                    v-model="end_date"
                    :disabled="!allows_crud"
                    :state="validateState('end_time')"
                    placeholder="Definir fecha de entrega"
                    aria-describedby="input-end_date-feedback"
                    size="sm"
                    class="mt-1"
                  ></b-form-datepicker>
                  <b-form-invalid-feedback id="input-end_date-feedback"
                    >Debe ingresar una fecha de entrega
                    válida.</b-form-invalid-feedback
                  >
                </div>
                <b-button
                  v-if="allows_crud"
                  class="mt-1 ml-2 p-1 btn-clear"
                  variant="danger"
                  size="sm"
                  @click="clearEndTime()"
                >
                  <b-icon-trash></b-icon-trash>
                </b-button>
              </div>
            </template>
          </b-form-group>

          <!-- Hora de Entrega -->
          <b-form-group
            label="Hora de Entrega"
            label-for="input-end_time"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <template>
              <div>
                <b-form-timepicker
                  id="input-end_time"
                  v-model="end_time"
                  :disabled="!allows_crud"
                  class="mt-1 mb-1 end-time-input"
                  :state="validateStateEndTime()"
                  placeholder="Definir hora de entrega"
                  aria-describedby="input-end_time-feedback"
                  size="sm"
                  locale="en"
                ></b-form-timepicker>
                <b-form-invalid-feedback id="input-end_time-feedback"
                  >Debe ingresar la hora de entrega.</b-form-invalid-feedback
                >
              </div>
            </template>
          </b-form-group>
          <!-- Duracion Nuevo Formato / Tiempo para el desarrollo-->
          <!-- <b-form-group
            label="Tiempo para el desarrollo"
            label-for="input-duration"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="mt-1 mb-3 p-0"
          >
            <template>
              <div class="row row-title-duration"> -->
          <!-- Primera fila con títulos -->
          <!-- <div class="col">
                  <label><b>Semanas</b></label>
                </div>
                <div class="col">
                  <label><b>Días</b></label>
                </div>
                <div class="col">
                  <label><b>Horas</b></label>
                </div>
                <div class="col">
                  <label><b>Minutos</b></label>
                </div>
              </div>
              <div class="row"> -->
          <!-- Segunda fila con inputs -->
          <!-- Semanas -->
          <!-- <div class="col">
                  <b-form-input
                    id="input-weeks"
                    class="input-duration-times"
                    type="number"
                    v-model="$v.duration_time.weeks_time.$model"
                    :disabled="!allows_crud"
                    :state="validateStateDuration('weeks_time')"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-duration-feedback">
                    Este campo debe ser mayor o igual a 0
                  </b-form-invalid-feedback>
                </div> -->
          <!-- Días -->
          <!-- <div class="col">
                  <b-form-input
                    id="input-days"
                    class="input-duration-times"
                    type="number"
                    v-model="$v.duration_time.days_time.$model"
                    :state="validateStateDuration('days_time')"
                    :disabled="!allows_crud"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-duration-feedback">
                    Este campo debe ser mayor o igual a 0 y menor que 6
                  </b-form-invalid-feedback>
                </div> -->
          <!-- Horas -->
          <!-- <div class="col">
                  <b-form-input
                    id="input-hours"
                    class="input-duration-times"
                    type="number"
                    v-model="$v.duration_time.hours_time.$model"
                    :state="validateStateDuration('hours_time')"
                    :disabled="!allows_crud"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-duration-feedback">
                    Este campo debe ser mayor o igual a 0 y menor que 23
                  </b-form-invalid-feedback>
                </div> -->
          <!-- Minutos -->
          <!-- <div class="col">
                  <b-form-input
                    id="input-minutes"
                    class="input-duration-times"
                    type="number"
                    v-model="$v.duration_time.minutes_time.$model"
                    :state="validateStateDuration('minutes_time')"
                    :disabled="!allows_crud"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-duration-feedback">
                    Este campo debe ser mayor o igual a 0 y menor que 59
                  </b-form-invalid-feedback>
                </div>
              </div>
            </template>
          </b-form-group> -->

          <!-- Duración -->
          <!-- <b-form-group
            label="Duración"
            label-for="input-duration"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <template>
              <div class="d-flex">
                <b-form-timepicker
                  id="input-duration"
                  v-model="$v.instrument.time.$model"
                  :state="validateState('time')"
                  class="mt-1 mb-1"
                  placeholder="Definir duración"
                  aria-describedby="input-duration-feedback"
                  size="sm"
                ></b-form-timepicker>
                <b-form-invalid-feedback id="input-duration-feedback"
                  >Este campo es obligatorio y debe ser mayor de o igual a
                  0.</b-form-invalid-feedback
                >
                <div>
                  <b-button
                    class="mt-1 ml-2 p-1"
                    variant="danger"
                    size="sm"
                    @click="clearDuration()"
                  >
                    <b-icon-trash></b-icon-trash>
                  </b-button>
                </div>
              </div>
            </template>
          </b-form-group> -->

          <!-- Visible para evaluatee -->
          <b-form-group
            label-for="input-visible_by_evaluatees"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <template #label>
              <div class="block-title-container">
                <div>Visible para evaluados</div>
                <div class="ml-2">
                  <InfoTooltip
                    :tooltip_text="`Esta opción le permite al Estudiante visualizar el ${$getVisibleNames(
                      'evaluations2.test',
                      false,
                      'Instrumento'
                    )}`"
                  ></InfoTooltip>
                </div>
              </div>
            </template>
            <b-form-checkbox
              class="mt-2"
              v-model="instrument.visible_by_evaluatees"
              size="md"
              :disabled="!allows_crud"
            >
            </b-form-checkbox>
          </b-form-group>
          <!-- Bloquear crear Preguntas -->
          <b-form-group
            label-for="input-student_can_upload_attachments"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <template #label>
              <div class="block-title-container">
                <div>Permitir adjuntar archivos</div>
                <div class="ml-2">
                  <InfoTooltip
                    :tooltip_text="`Esta opción permite al Estudiante adjuntar archivos para sus ${$getVisibleNames(
                      'evaluations2.evaluation',
                      true,
                      'Evaluaciones'
                    )}`"
                  ></InfoTooltip>
                </div>
              </div>
            </template>
            <b-form-checkbox
              class="mt-2"
              v-model="instrument.student_can_upload_attachments"
              size="md"
              :disabled="!allows_crud"
            >
            </b-form-checkbox>
          </b-form-group>
          <!-- Bloquear crear Descriptores -->
          <b-form-group
            v-if="user && !user.groups.includes(6)"
            label-for="input-descriptor_creation_is_locked"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <template #label>
              <div class="block-title-container">
                <div>
                  {{
                    `Bloquear creación de ${$getVisibleNames(
                      "evaluations2.testdescriptor",
                      false,
                      "Descriptor"
                    )}`
                  }}
                </div>
                <div class="ml-2">
                  <InfoTooltip
                    :tooltip_text="`Esta opción permite bloquear al perfil del Profesor(a) crear nuevos ${$getVisibleNames(
                      'evaluations2.testdescriptor',
                      true,
                      'Descriptores'
                    )} en la vista del ${$getVisibleNames(
                      'evaluations2.test',
                      false,
                      'Instrumento'
                    )}`"
                  ></InfoTooltip>
                </div>
              </div>
            </template>
            <b-form-checkbox
              class="mt-2"
              v-model="instrument.descriptor_creation_is_locked"
              size="md"
              :disabled="locked_instrument || !allows_crud"
            >
            </b-form-checkbox>
          </b-form-group>
          <!-- Bloquear crear Segmentos -->
          <b-form-group
            v-if="user && !user.groups.includes(6)"
            :label="`Bloquear creación de ${$getVisibleNames(
              'evaluations2.testsegment',
              true,
              'Segmentos'
            )}`"
            label-for="input-segment_creation_is_locked"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <template #label>
              <div class="block-title-container">
                <div>
                  {{
                    `Bloquear creación de ${$getVisibleNames(
                      "evaluations2.testsegment",
                      true,
                      "Segmentos"
                    )}`
                  }}
                </div>
                <div class="ml-2">
                  <InfoTooltip
                    :tooltip_text="`Esta opción permite bloquear la creación de ${$getVisibleNames(
                      'evaluations2.testsegment',
                      true,
                      'Segmentos'
                    )} a los Docentes`"
                  ></InfoTooltip>
                </div>
              </div>
            </template>
            <b-form-checkbox
              class="mt-2"
              v-model="instrument.segment_creation_is_locked"
              :disabled="locked_instrument || !allows_crud"
              size="md"
            >
            </b-form-checkbox>
          </b-form-group>
          <!-- Bloquear crear Preguntas -->
          <b-form-group
            v-if="user && !user.groups.includes(6)"
            label-for="input-question_creation_is_locked"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <template #label>
              <div class="block-title-container">
                <div>Bloquear creación de preguntas</div>
                <div class="ml-2">
                  <InfoTooltip
                    :tooltip_text="`Esta opción permite bloquear la creación de preguntas a los Docentes`"
                  ></InfoTooltip>
                </div>
              </div>
            </template>
            <b-form-checkbox
              class="mt-2"
              v-model="instrument.question_creation_is_locked"
              size="md"
              :disabled="locked_instrument || !allows_crud"
            >
            </b-form-checkbox>
          </b-form-group>
          <!-- Bloquear Realizar Grupos -->
          <b-form-group
            v-if="
              evaluativeAgent &&
              !(
                evaluativeAgent.internal_use_id ==
                  'duoc_autoevaluacion_evaluative_agent' ||
                this.$equals(evaluativeAgent.name, 'Autoevaluación')
              )
            "
            :label="`Permitir creación de ${$getVisibleNames(
              'evaluations2.studentgroup',
              true,
              'Grupos'
            )}`"
            label-for="input-allow_group_creation"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <template #label>
              <div class="block-title-container">
                <div>
                  {{
                    `Permitir creación de ${$getVisibleNames(
                      "evaluations2.studentgroup",
                      true,
                      "Grupos"
                    )}`
                  }}
                </div>
                <div class="ml-2">
                  <InfoTooltip
                    :tooltip_text="`Esta opción permite la creación de ${$getVisibleNames(
                      'evaluations2.studentgroup',
                      true,
                      'Grupos'
                    )} a los Docentes`"
                  ></InfoTooltip>
                </div>
              </div>
            </template>
            <b-form-checkbox
              class="mt-2"
              v-model="instrument.allow_group_creation"
              :disabled="locked_instrument || !allows_crud"
              size="md"
            >
            </b-form-checkbox>
          </b-form-group>
          <!-- Fecha de Publicación -->
          <!-- <b-form-group
      label="Hora de Publicación:"
      label-for="input-publish_time"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <b-form-timepicker
        id="input-publish_time"
        v-model="publish_time"
        class="mt-1 mb-1"
        placeholder="Definir hora de publicación"
        aria-describedby="input-publish_time-feedback"
        size="sm"
      ></b-form-timepicker>
      <b-form-invalid-feedback id="input-publish_time-feedback"
        >Debe ingresar la hora de publicación.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group
      label="Fecha de Publicación:"
      label-for="input-publish_date"
      label-cols="0"
      label-cols-sm="3"
      label-cols-md="3"
      class="m-0 p-0"
    >
      <b-form-datepicker
        id="input-publish_date"
        v-model="publish_date"
        placeholder="Definir fecha de publicación"
        aria-describedby="input-publish_date-feedback"
        size="sm"
        class="mt-1"
      ></b-form-datepicker>
      <b-form-invalid-feedback id="input-publish_date-feedback"
        >Debe ingresar una fecha de publicación válida.</b-form-invalid-feedback
      >
    </b-form-group> -->
          <!-- Anexos para el Docente -->
          <b-form-group
            label="Anexos para el Docente"
            label-for="file-attachment"
            label-cols="0"
            label-cols-sm="3"
            class="m-0 p-0 mb-2"
          >
            <div class="d-flex">
              <b-button
                v-if="allows_crud"
                @click="$bvModal.show('modal-select-files_for_professor')"
                size="sm"
                class="open-file-selector mt-1"
              >
                Seleccionar Anexos
              </b-button>
            </div>
            <template v-if="instrument.files_for_professor.length > 0">
              <FileContainer
                :Files="instrument.files_for_professor"
                :allows_crud="allows_crud"
                @deleteFile="slotDeleteFileProfessor"
              ></FileContainer>
            </template>
            <b-modal
              id="modal-select-files_for_professor"
              title="Seleccionar Anexos"
              hide-footer
            >
              <FileSelector @created="slotCreatedFileProfessor"></FileSelector>
            </b-modal>
          </b-form-group>
          <!-- Archivos para el estudiante-->
          <b-form-group
            label="Anexos para el estudiante"
            label-for="file-attachment"
            label-cols="0"
            label-cols-sm="3"
            class="m-0 p-0 mb-2"
          >
            <div>
              <b-button
                v-if="allows_crud"
                @click="$bvModal.show('modal-select-file')"
                size="sm"
                class="open-file-selector mt-1"
                >Seleccionar Anexos
              </b-button>
            </div>

            <template v-if="instrument.files.length > 0">
              <FileContainer
                :Files="instrument.files"
                :allows_crud="allows_crud"
                @deleteFile="slotDeleteFile"
              ></FileContainer>
            </template>
            <b-modal
              id="modal-select-file"
              title="Seleccionar Anexos"
              hide-footer
            >
              <FileSelector @created="slotCreatedFile"></FileSelector>
            </b-modal>
          </b-form-group>
        </div>
      </section>
      <!-- ------------------------------PASO 2------------------------------ -->
      <div class="hidden-step" :class="{ 'is-shown': this.step == 2 }">
        <div class="step-grid mb-5">
          <div class="step-icon-border second-load-border-step1 mr-5 done">
            <b-icon-list-ul
              id="step1"
              class="step-icon second-load-icon-step1"
              variant="success"
            ></b-icon-list-ul>
            <p class="step-caption text-success">
              Paso 1: Detalles del
              {{ $getVisibleNames("evaluations2.test", false, "Instrumento") }}
              <b-icon-check2-circle variant="success"></b-icon-check2-circle>
            </p>
          </div>
          <div class="step-icon-border second-load-border mx-5">
            <b-icon-people-fill
              class="step-icon-info second-load-icon"
            ></b-icon-people-fill>
            <p class="step-caption text-success">Paso 2: Asignar Evaluado(s)</p>
          </div>
          <div class="step-icon-border ml-5">
            <b-icon-person-check-fill
              class="step-icon"
            ></b-icon-person-check-fill>
            <p class="step-caption">Paso 3: Asignar Evaluador(es)</p>
          </div>
        </div>
        <div class="progress second-load done"></div>
        <div class="container-group">
          <!-- Acá va el contenido -->
          <NewUserList
            :key="'evaluatees:' + instrument_users.id"
            :key_component="'evaluatees:' + instrument_users.id"
            :users_ids="evaluatees"
            :allows_crud="
              !ClosedEvaluatee &&
              allows_crud &&
              ((user && !user.groups.includes(6)) ||
                (institution && institution.internal_use_id != 'duoc_uc'))
            "
            :pre_multiple_search="['Estudiante']"
            @selected_user="slotSelectedEvaluatees"
          ></NewUserList>
        </div>
      </div>

      <!-- ------------------------------PASO 3------------------------------ -->
      <div class="hidden-step" :class="{ 'is-shown': this.step == 3 }">
        <div class="step-grid mb-5">
          <div class="step-icon-border third-load-border-step1 mr-5 done">
            <b-icon-list-ul
              id="step1"
              class="step-icon third-load-icon-step1"
              variant="success"
            ></b-icon-list-ul>
            <p class="step-caption text-success">
              Paso 1: Detalles del
              {{ $getVisibleNames("evaluations2.test", false, "Instrumento") }}
              <b-icon-check2-circle variant="success"></b-icon-check2-circle>
            </p>
          </div>
          <div class="step-icon-border third-load-border-step2 mx-5 done">
            <b-icon-people-fill
              class="step-icon third-load-icon-step2"
              variant="success"
            ></b-icon-people-fill>
            <p class="step-caption text-success">
              Paso 2: Asignar Evaluado(s)
              <b-icon-check2-circle variant="success"></b-icon-check2-circle>
            </p>
          </div>
          <div
            class="step-icon-border third-load-border ml-5"
            :class="[{ done: saved }, { info: !saved }]"
          >
            <b-icon-person-check-fill
              class="step-icon third-load-icon"
              :style="[
                saved
                  ? { color: 'white !important' }
                  : { color: 'var(--secondary-color)' },
              ]"
            ></b-icon-person-check-fill>
            <p
              class="step-caption"
              :class="[{ 'text-success': saved }, { 'text-success': !saved }]"
            >
              Paso 3: Asignar Evaluador(es)<b-icon-check2-circle
                :style="[saved ? { display: 'inline' } : { display: 'none' }]"
                variant="success"
              ></b-icon-check2-circle>
            </p>
          </div>
        </div>
        <span
          class="progress third-load done"
          :class="[{ fill: saved }]"
        ></span>
        <div class="container-group">
          <!-- Acá va el contenido -->

          <!-- <template v-if="instrument_type.type == 3">
            <NewUserList
              :key="'evaluatees:' + instrument_users.id"
              :key_component="'evaluators:' + instrument_users.id"
              :users_ids="evaluators"
              :pre_filter_array="['groups__id__exclude__in=7']"
              @selected_user="slotSelectedEvaluators"
            ></NewUserList>
          </template>
          <template v-else> -->
          <NewUserList
            :key="'evaluatees:' + instrument_users.id"
            :key_component="'evaluators:' + instrument_users.id"
            :allows_crud="
              allows_crud &&
              ((user && !user.groups.includes(6)) ||
                (institution && institution.internal_use_id != 'duoc_uc'))
            "
            :users_ids="evaluators"
            @selected_user="slotSelectedEvaluators"
          ></NewUserList>
          <!-- </template> -->
        </div>
      </div>
    </div>
    <div v-else-if="show_step">
      <section>
        <div>
          <!-- Título -->
          <b-form-group
            label="Título del Instrumento"
            label-for="input-name"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <b-form-input
              id="input-name"
              v-model="$v.instrument.title.$model"
              :state="validateState('title')"
              :disabled="locked_instrument || !allows_crud"
              aria-describedby="input-name-feedback"
              size="sm"
            ></b-form-input>
            <b-form-invalid-feedback id="input-name-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>

          <!-- Descripción -->
          <b-form-group
            v-if="institution && institution.internal_use_id != 'duoc_uc'"
            label="Descripción"
            label-for="input-description"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <template v-if="!locked_instrument && allows_crud">
              <div
                v-b-tooltip.v-secondary="'Editar Descripción'"
                class="instrument-form-description rich-text-content mb-2"
                v-html="$v.instrument.description.$model"
                @click="
                  $bvModal.show(
                    `edit-instrument-description-modal-${instrument.id}`
                  )
                "
              ></div>
            </template>
            <template v-else>
              <div
                v-b-tooltip.v-secondary="'Editar Descripción'"
                class="instrument-form-description rich-text-content mb-2"
                v-html="$v.instrument.description.$model"
              ></div>
            </template>
            <b-modal
              :id="`edit-instrument-description-modal-${instrument.id}`"
              title="Editar Descripción de la Evaluación"
              size="lg"
              hide-footer
            >
              <NewRichTextEditor
                :Object="instrument"
                :Text="instrument.description"
                @save="patchInstrument"
                @close="
                  $bvModal.hide(
                    `edit-instrument-description-modal-${instrument.id}`
                  )
                "
              ></NewRichTextEditor>
            </b-modal>
            <b-form-invalid-feedback id="input-description-feedback"
              >Este campo es opcional.</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group
            :label="
              $getVisibleNames(
                'teaching.evaluationsituation',
                false,
                'Situación Evaluativa'
              )
            "
            label-for="select-evaluation_situation"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <b-form-select
              id="select-evaluation_situation"
              v-model="$v.instrument.evaluation_situation.$model"
              :options="evaluationSituationsFilter"
              :disabled="locked_instrument || !allows_crud"
              value-field="id"
              text-field="name"
              :state="validateState('evaluation_situation')"
              aria-describedby="select-evaluation_situation-feedback"
              size="sm"
              class="mt-1"
            >
              <!-- <template #first>
                <b-form-select-option :value="null"
                  >N/A
                </b-form-select-option></template
              > -->
            </b-form-select>
            <b-form-invalid-feedback id="select-evaluation_situation-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
          <!-- Tipo => Agente Evaluativo -->
          <b-form-group
            label="Agente Evaluativo"
            label-for="select-evaluative_agent"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <b-form-select
              id="select-evaluative_agent"
              v-model="$v.instrument_type.evaluative_agent.$model"
              :state="validateStateType('evaluative_agent')"
              :options="instrumentTypesFilter"
              :disabled="locked_instrument || !allows_crud"
              value-field="id"
              text-field="name"
              aria-describedby="select-evaluative_agent-feedback"
              size="sm"
            ></b-form-select>
            <b-form-invalid-feedback id="select-evaluative_agent-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group
            v-if="
              institution &&
              institution.internal_use_id != 'duoc_uc' &&
              evaluativeAgent &&
              (evaluativeAgent.internal_use_id ==
                'duoc_autoevaluacion_evaluative_agent' ||
                $equals(evaluativeAgent.name, 'Autoevaluación'))
            "
            label-for="input-student_can_auto_evaluate"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <template #label>
              <div class="block-title-container">
                <div>Permitir auto evaluarse</div>
                <div class="ml-2">
                  <InfoTooltip
                    :tooltip_text="`Esta opción permite al Estudiante auto evaluarse al momento de contestar.`"
                  ></InfoTooltip>
                </div>
              </div>
            </template>
            <b-form-checkbox
              class="mt-2"
              v-model="instrument.student_can_auto_evaluate"
              size="md"
              :disabled="!allows_crud"
            >
            </b-form-checkbox>
          </b-form-group>
          <!-- Ponderación -->
          <b-form-group
            label="Ponderación"
            label-for="input-weighing"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <b-form-input
              id="input-weighing"
              v-model="$v.instrument.weighing.$model"
              :state="validateState('weighing')"
              :disabled="locked_instrument || !allows_crud"
              type="number"
              aria-describedby="input-weighing-feedback"
              size="sm"
            ></b-form-input>
            <div class="weighing-aviable">
              Ponderación disponible
              <span
                :class="{
                  'colorup-weighing-available': maxWeighingTest > 0,
                  'colordown-weighing-available': maxWeighingTest <= 0,
                }"
                >{{ maxWeighingTest > 0 ? maxWeighingTest : 0 }}%</span
              >
            </div>
            <b-form-invalid-feedback id="input-weighing-feedback">
              <div>
                Este campo es obligatorio, debe ser mayor o igual que 0
                <span v-if="instrument.weighing && instrument.weighing > 0">
                  y no puede superar
                  {{
                    parseFloat(maxWeighingTest) +
                    parseFloat(instrument.weighing)
                  }}%.
                </span>
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- Puntaje -->
          <b-form-group
            label="Puntaje"
            label-for="input-max_score"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <b-form-input
              id="input-max_score"
              v-model="$v.instrument.max_score.$model"
              :state="validateState('max_score')"
              type="number"
              :disabled="locked_instrument || !allows_crud"
              aria-describedby="input-max_score-feedback"
              size="sm"
            ></b-form-input>
            <b-form-invalid-feedback id="input-max_score-feedback"
              >Este campo es obligatorio y debe ser mayor de
              0.</b-form-invalid-feedback
            >
          </b-form-group>
          <!-- Duracion Nuevo Formato / Tiempo para el desarrollo-->
          <b-form-group
            v-if="institution && institution.internal_use_id == 'duoc_uc'"
            label="Tiempo para el desarrollo"
            label-for="input-duration"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="mt-1 mb-3 p-0"
          >
            <template>
              <div class="row row-title-duration">
                <!-- Primera fila con títulos -->
                <div class="col">
                  <label><b>Semanas</b></label>
                </div>
                <div class="col">
                  <label><b>Días</b></label>
                </div>
                <div class="col">
                  <label><b>Horas</b></label>
                </div>
                <div class="col">
                  <label><b>Minutos</b></label>
                </div>
              </div>
              <div class="row">
                <!-- Segunda fila con inputs -->
                <!-- Semanas -->
                <div class="col">
                  <b-form-input
                    id="input-weeks"
                    class="input-duration-times"
                    type="number"
                    v-model="$v.duration_time.weeks_time.$model"
                    :disabled="locked_instrument || !allows_crud"
                    :state="validateStateDuration('weeks_time')"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-duration-feedback">
                    Este campo debe ser mayor o igual a 0
                  </b-form-invalid-feedback>
                </div>
                <!-- Días -->
                <div class="col">
                  <b-form-input
                    id="input-days"
                    class="input-duration-times"
                    type="number"
                    v-model="$v.duration_time.days_time.$model"
                    :disabled="locked_instrument || !allows_crud"
                    :state="validateStateDuration('days_time')"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-duration-feedback">
                    Este campo debe ser mayor o igual a 0 y menor que 6
                  </b-form-invalid-feedback>
                </div>
                <!-- Horas -->
                <div class="col">
                  <b-form-input
                    id="input-hours"
                    class="input-duration-times"
                    type="number"
                    v-model="$v.duration_time.hours_time.$model"
                    :disabled="locked_instrument || !allows_crud"
                    :state="validateStateDuration('hours_time')"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-duration-feedback">
                    Este campo debe ser mayor o igual a 0 y menor que 23
                  </b-form-invalid-feedback>
                </div>
                <!-- Minutos -->
                <div class="col">
                  <b-form-input
                    id="input-minutes"
                    class="input-duration-times"
                    type="number"
                    v-model="$v.duration_time.minutes_time.$model"
                    :disabled="locked_instrument || !allows_crud"
                    :state="validateStateDuration('minutes_time')"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-duration-feedback">
                    Este campo debe ser mayor o igual a 0 y menor que 59
                  </b-form-invalid-feedback>
                </div>
              </div>
            </template>
          </b-form-group>

          <!-- Bloquear crear Descriptores -->
          <b-form-group
            v-if="user && !user.groups.includes(6)"
            label-for="input-descriptor_creation_is_locked"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <template #label>
              <div class="block-title-container">
                <div>
                  {{
                    `Bloquear creación de ${$getVisibleNames(
                      "evaluations2.testdescriptor",
                      false,
                      "Descriptor"
                    )}`
                  }}
                </div>
                <div class="ml-2">
                  <InfoTooltip
                    :tooltip_text="`Esta opción permite bloquear la creación de ${$getVisibleNames(
                      'evaluations2.testdescriptor',
                      true,
                      'Descriptores'
                    )} a los Docentes`"
                  ></InfoTooltip>
                </div>
              </div>
            </template>
            <b-form-checkbox
              class="mt-2"
              v-model="instrument.descriptor_creation_is_locked"
              :disabled="locked_instrument || !allows_crud"
              size="md"
            >
            </b-form-checkbox>
          </b-form-group>
          <!-- Bloquear crear Segmentos -->
          <b-form-group
            v-if="user && !user.groups.includes(6)"
            :label="`Bloquear creación de ${$getVisibleNames(
              'evaluations2.testsegment',
              true,
              'Segmentos'
            )}`"
            label-for="input-segment_creation_is_locked"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <template #label>
              <div class="block-title-container">
                <div>
                  {{
                    `Bloquear creación de ${$getVisibleNames(
                      "evaluations2.testsegment",
                      true,
                      "Segmentos"
                    )}`
                  }}
                </div>
                <div class="ml-2">
                  <InfoTooltip
                    :tooltip_text="`Esta opción permite bloquear la creación de ${$getVisibleNames(
                      'evaluations2.testsegment',
                      true,
                      'Segmentos'
                    )} a los Docentes`"
                  ></InfoTooltip>
                </div>
              </div>
            </template>
            <b-form-checkbox
              class="mt-2"
              v-model="instrument.segment_creation_is_locked"
              :disabled="locked_instrument || !allows_crud"
              size="md"
            >
            </b-form-checkbox>
          </b-form-group>
          <!-- Bloquear crear Preguntas -->
          <b-form-group
            v-if="user && !user.groups.includes(6)"
            label-for="input-question_creation_is_locked"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <template #label>
              <div class="block-title-container">
                <div>Bloquear creación de Preguntas</div>
                <div class="ml-2">
                  <InfoTooltip
                    :tooltip_text="`Esta opción permite bloquear la creación de preguntas a los Docentes`"
                  ></InfoTooltip>
                </div>
              </div>
            </template>
            <b-form-checkbox
              class="mt-2"
              v-model="instrument.question_creation_is_locked"
              :disabled="locked_instrument || !allows_crud"
              size="md"
            >
            </b-form-checkbox>
          </b-form-group>
          <!-- Bloquear Realizar Grupos -->
          <b-form-group
            v-if="
              user &&
              !user.groups.includes(6) &&
              evaluativeAgent &&
              !(
                evaluativeAgent.internal_use_id ==
                  'duoc_autoevaluacion_evaluative_agent' ||
                this.$equals(evaluativeAgent.name, 'Autoevaluación')
              )
            "
            :label="`Permitir creación de ${$getVisibleNames(
              'evaluations2.studentgroup',
              true,
              'Grupos'
            )}`"
            label-for="input-allow_group_creation"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <template #label>
              <div class="block-title-container">
                <div>
                  {{
                    `Permitir creación de ${$getVisibleNames(
                      "evaluations2.studentgroup",
                      true,
                      "Grupos"
                    )}`
                  }}
                </div>
                <div class="ml-2">
                  <InfoTooltip
                    :tooltip_text="`Esta opción permite la creación de ${$getVisibleNames(
                      'evaluations2.studentgroup',
                      true,
                      'Grupos'
                    )} a los Docentes`"
                  ></InfoTooltip>
                </div>
              </div>
            </template>
            <b-form-checkbox
              class="mt-2"
              v-model="instrument.allow_group_creation"
              :disabled="locked_instrument || !allows_crud"
              size="md"
            >
            </b-form-checkbox>
          </b-form-group>
          <!-- <b-form-group
            label="Habilitar/Deshabilitar Fecha:"
            label-for="input-max_score"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <b-form-checkbox
              id="checkbox-stard_date-step1"
              v-model="instrument.visible_by_evaluatees"
              name="checkbox-stard_date-step1"
              class="mt-1 ml-2"
            >
            </b-form-checkbox>
          </b-form-group> -->
          <!-- Fecha de Inicio -->

          <!-- <b-form-group
            v-if="instrument.visible_by_evaluatees"
            label-for="input-start_date"
            label-cols="0"
            label-cols-sm="4"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <template #label>
              <div class="d-flex">
                <div class="mr-1 mt-1">Fecha de Inicio</div>
              </div>
            </template>
            <b-form-datepicker
              id="input-start_date"
              v-model="start_date"
              :state="validateState('start_time')"
              placeholder="Definir fecha de inicio"
              aria-describedby="input-start_date-feedback"
              size="sm"
              class="mt-1"
            ></b-form-datepicker>
            <b-form-invalid-feedback id="input-start_date-feedback"
              >Debe ingresar una fecha de inicio
              válida.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group
            v-if="instrument.visible_by_evaluatees"
            label="Hora de Inicio:"
            label-for="input-start_time"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <b-form-timepicker
              id="input-start_time"
              v-model="start_time"
              :state="validateStateStartTime()"
              class="mt-1 mb-1"
              placeholder="Definir hora de inicio"
              aria-describedby="input-start_time-feedback"
              size="sm"
            ></b-form-timepicker>
            <b-form-invalid-feedback id="input-start_time-feedback"
              >Debe ingresar la hora de inicio.</b-form-invalid-feedback
            >
          </b-form-group> -->

          <!-- Fecha de Entrega -->
          <!-- <b-form-group
            v-if="instrument.visible_by_evaluatees"
            label="Fecha de Entrega:"
            label-for="input-end_date"
            label-cols="0"
            label-cols-sm="4"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <template #label>
              <div class="d-flex">
                <div class="mr-1 mt-1">Fecha de Entrega</div>
              </div>
            </template>
            <b-form-datepicker
              id="input-end_date"
              v-model="end_date"
              :state="validateState('end_time')"
              placeholder="Definir fecha de entrega"
              aria-describedby="input-end_date-feedback"
              size="sm"
              class="mt-1"
            ></b-form-datepicker>
            <b-form-invalid-feedback id="input-end_date-feedback"
              >Debe ingresar una fecha de entrega
              válida.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group
            v-if="instrument.visible_by_evaluatees"
            label="Hora de Entrega:"
            label-for="input-end_time"
            label-cols="0"
            label-cols-sm="3"
            label-cols-md="3"
            class="m-0 p-0"
          >
            <b-form-timepicker
              id="input-end_time"
              v-model="end_time"
              class="mt-1 mb-1"
              :state="validateStateEndTime()"
              placeholder="Definir hora de entrega"
              aria-describedby="input-end_time-feedback"
              size="sm"
            ></b-form-timepicker>
            <b-form-invalid-feedback id="input-end_time-feedback"
              >Debe ingresar la hora de entrega.</b-form-invalid-feedback
            >
          </b-form-group> -->
          <!-- Anexos para el Docente-->
          <b-form-group
            label="Anexos para el Docente"
            label-for="file-attachment"
            label-cols="0"
            label-cols-sm="3"
            class="m-0 p-0 mb-2"
          >
            <div class="d-flex">
              <b-button
                v-if="allows_crud"
                @click="$bvModal.show('modal-select-files_for_professor')"
                size="sm"
                class="open-file-selector mt-1"
              >
                Seleccionar Anexos
              </b-button>
            </div>
            <template v-if="instrument.files_for_professor.length > 0">
              <FileContainer
                :Files="instrument.files_for_professor"
                :allows_crud="allows_crud"
                @deleteFile="slotDeleteFileProfessor"
              ></FileContainer>
            </template>
            <b-modal
              id="modal-select-files_for_professor"
              title="Seleccionar Anexos"
              hide-footer
            >
              <FileSelector @created="slotCreatedFileProfessor"></FileSelector>
            </b-modal>
          </b-form-group>
          <!-- Anexos para el Estudiante-->
          <b-form-group
            label="Anexos para el Estudiante"
            label-for="file-attachment"
            label-cols="0"
            label-cols-sm="3"
            class="m-0 p-0 mb-2"
          >
            <div class="d-flex">
              <b-button
                v-if="allows_crud"
                @click="$bvModal.show('modal-select-file')"
                size="sm"
                class="open-file-selector mt-1"
              >
                Seleccionar Anexos
              </b-button>
            </div>
            <template v-if="instrument.files.length > 0">
              <FileContainer
                :Files="instrument.files"
                :allows_crud="allows_crud"
                @deleteFile="slotDeleteFile"
              ></FileContainer>
            </template>
            <b-modal
              id="modal-select-file"
              title="Seleccionar Anexos"
              hide-footer
            >
              <FileSelector @created="slotCreatedFile"></FileSelector>
            </b-modal>
          </b-form-group>
        </div>
      </section>
    </div>
    <div class="row my-1">
      <div
        class="col"
        v-if="show_delete_button && !locked_instrument && allows_crud"
        style="text-align: left"
      >
        <b-button
          class="mx-1"
          size="sm"
          variant="danger"
          @click="deleteInstrument"
          >Eliminar</b-button
        >
      </div>
      <div class="col" style="text-align: right">
        <b-button
          class="mr-1"
          size="sm"
          v-if="step != 1 && !show_step"
          @click.prevent="prevStep"
        >
          Paso anterior
        </b-button>
        <b-button
          class="mr-1"
          size="sm"
          v-if="step != total_steps && !show_step"
          @click.prevent="nextStep"
        >
          Siguiente paso
        </b-button>
        <b-button
          class="mr-1"
          size="sm"
          v-if="allows_crud"
          @click="saveInstrument"
          >Guardar</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId, toast } from "../../../utils/utils";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import {
  required,
  minValue,
  numeric,
  maxValue,
} from "vuelidate/lib/validators";

export default {
  name: "NewInstrumentForm",
  components: {
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
    FileSelector: () => import("@/components/reusable/FileSelector"),
    FileContainer: () => import("@/components/reusable/FileContainer"),
    NewUserList: () => import("@/components/reusable/NewUserList"),
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
  },
  props: {
    evaluation_id: {
      type: Number,
      required: true,
    },
    show_step: {
      type: Boolean,
      default: false,
    },
    section: {
      type: Object,
    },
    Instrument: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          title: "",
          description: "",
          type: null,
          weighing: 0,
          max_score: 0,
          evaluation_situation: null,
          visible_by_evaluatees: false,
          question_creation_is_locked: false,
          student_can_upload_attachments: false,
          allow_group_creation: false,
          student_can_auto_evaluate: false,
          descriptor_creation_is_locked: false,
          segment_creation_is_locked: false,
          start_time: this.show_step ? null : this.$moment(),
          end_time: this.show_step
            ? null
            : this.$moment().add(1, "days").add(2, "hours"),
          publish_date: null,
          evaluation: this.evaluation_id,
          test_type_default: null,
          files: [],
          files_for_professor: [],
          time: null,
        };
      },
    },
    ClosedEvaluatee: {
      type: Boolean,
      default: false,
    },
    allows_crud: {
      type: Boolean,
      default: true,
    },
    locked_instrument: {
      type: Boolean,
      default: false,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      step: 1,
      total_steps: 3,
      saved: false,
      instrument: { ...this.Instrument },
      instrument_type: {
        id: generateUniqueId(),
        evaluative_agent: null,
        test: this.Instrument.id,
      },
      instrument_users: {
        id: generateUniqueId(),
        evaluators: [],
        evaluatees: [],
        test: this.Instrument.id,
      },
      evaluators: [],
      evaluatees: [],
      start_time: this.show_step ? null : "09:00:00",
      end_time: this.show_step ? null : "09:00:00",
      start_date: this.show_step ? null : this.$moment(),
      end_date: this.show_step ? null : this.$moment().add(1, "days"),
      duration_time: {
        weeks_time: 0,
        days_time: 0,
        hours_time: 0,
        minutes_time: 0,
      },
      evaluation_situations: [],
      matt_pro_eva_evaluative_agents: [],
      matt_pro_eva_evaluation_situations: [],
      finished_mounted: false,
    };
  },
  validations: {
    instrument: {
      title: { required },
      description: {},
      evaluation_situation: {
        required,
      },
      weighing: {
        required,
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(100),
        isValid() {
          if (this.maxWeighingTest >= 0) return true;
          else return false;
        },
      },
      max_score: {
        required,
        numeric,
        minValue: minValue(1),
      },
      start_time: {
        isValid() {
          if (this.start_time != null && this.start_date == null) return false;
          else return true;
        },
      },
      end_time: {
        isValid() {
          if (this.end_time != null && this.end_date == null) return false;
          else return true;
        },
      },
      time: {},
    },
    duration_time: {
      weeks_time: {
        required,
        minValue: minValue(0),
      },
      days_time: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(6),
      },
      hours_time: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(23),
      },
      minutes_time: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(59),
      },
    },
    start_time: {
      isValid() {
        if (this.start_time == null && this.start_date != null) return false;
        else return true;
      },
    },
    end_time: {
      isValid() {
        if (this.end_time == null && this.end_date != null) return false;
        else return true;
      },
    },
    instrument_type: {
      evaluative_agent: { required },
    },
    instrument_users: {},
    validationGroup: [
      "instrument",
      "duration_time",
      "start_time",
      "end_time",
      "instrument_type",
      "instrument_users",
    ],
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      instrument_types: names.NEW_TEST_TYPES,
      new_tests: names.NEW_TESTS,
      new_test_groups: names.NEW_TEST_GROUPS,
    }),
    newTestGroups() {
      return this.new_test_groups.filter((x) => x.test == this.instrument.id);
    },
    maxWeighingTest() {
      let max_weighing = this.new_tests
        .filter(
          (x) =>
            x.evaluation == this.evaluation_id && x.id != this.instrument.id
        )
        .reduce((accumulator, x) => {
          return accumulator - x.weighing;
        }, 0);
      max_weighing += 100;
      if (this.instrument.weighing && parseFloat(this.instrument.weighing) > 0)
        max_weighing -= parseFloat(this.instrument.weighing);
      return max_weighing;
    },
    evaluativeAgent() {
      return this.instrument_types.find(
        (x) => x.id == this.instrument_type.evaluative_agent
      );
    },
    evaluationSituationsFilter() {
      if (this.matt_pro_eva_evaluation_situations.length > 0)
        return this.evaluation_situations.filter(
          (x) =>
            x.id == this.instrument.evaluation_situation ||
            this.matt_pro_eva_evaluation_situations.includes(x.id)
        );
      else return this.evaluation_situations;
    },
    instrumentTypesFilter() {
      if (this.matt_pro_eva_evaluative_agents.length > 0)
        return this.instrument_types.filter(
          (x) =>
            x.id == this.instrument_type.evaluative_agent ||
            this.matt_pro_eva_evaluative_agents.includes(x.id)
        );
      else return this.instrument_types;
    },
  },
  methods: {
    clearStartTime() {
      this.start_date = null;
      this.start_time = null;
      this.instrument.start_time = null;
    },
    clearEndTime() {
      this.end_date = null;
      this.end_time = null;
      this.instrument.end_time = null;
    },
    clearDuration() {
      this.instrument.time = null;
    },
    prevStep() {
      return this.step--;
    },
    nextStep() {
      this.$v.validationGroup.$touch();
      if (this.$v.validationGroup.$anyError) {
        return;
      }
      return this.step++;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.instrument[name];
      return $dirty ? !$error : null;
    },
    validateStateDuration(name) {
      const { $dirty, $error } = this.$v.duration_time[name];
      return $dirty ? !$error : null;
    },
    validateStateStartTime() {
      const { $dirty, $error } = this.$v.start_time;
      return $dirty ? !$error : null;
    },
    validateStateEndTime() {
      const { $dirty, $error } = this.$v.end_time;
      return $dirty ? !$error : null;
    },
    validateStateType(name) {
      const { $dirty, $error } = this.$v.instrument_type[name];
      return $dirty ? !$error : null;
    },
    patchInstrument(object, text) {
      this.instrument.description = text;
    },
    setStartDate() {
      if (this.start_date == null || this.start_time == null) {
        this.instrument.start_time = null;
        return;
      }
      let date = this.start_date + " " + this.start_time;
      this.instrument.start_time = date;
    },
    setEndDate() {
      if (this.end_date == null || this.end_time == null) {
        this.instrument.end_time = null;
        return;
      }
      let date = this.end_date + " " + this.end_time;
      this.instrument.end_time = date;
    },
    slotCreatedFile(file) {
      if (file) {
        this.instrument.files.push(file.id);
        if (!isNaN(this.instrument.id)) {
          this.$store.dispatch(names.PATCH_NEW_TEST, {
            new_test_id: this.instrument.id,
            item: {
              files: this.instrument.files,
            },
          });
        }
      }
      this.$bvModal.hide("modal-select-file");
    },
    slotCreatedFileProfessor(file) {
      if (file) {
        this.instrument.files_for_professor.push(file.id);
        if (!isNaN(this.instrument.id)) {
          this.$store.dispatch(names.PATCH_NEW_TEST, {
            new_test_id: this.instrument.id,
            item: {
              files_for_professor: this.instrument.files_for_professor,
            },
          });
        }
      }
      this.$bvModal.hide("modal-select-files_for_professor");
    },
    slotDeleteFile(file_id) {
      const index = this.instrument.files.indexOf(file_id);
      if (index != -1) this.instrument.files.splice(index, 1);
      const index2 = this.Instrument.files.indexOf(file_id);
      if (index2 != -1) this.Instrument.files.splice(index, 1);
    },
    slotDeleteFileProfessor(file_id) {
      const index = this.instrument.files_for_professor.indexOf(file_id);
      if (index != -1) this.instrument.files_for_professor.splice(index, 1);
      const index2 = this.Instrument.files_for_professor.indexOf(file_id);
      if (index2 != -1) this.Instrument.files_for_professor.splice(index, 1);
    },
    slotSelectedEvaluatees(users) {
      this.instrument_users.evaluatees = users.map((x) => x.id);
    },
    slotSelectedEvaluators(users) {
      this.instrument_users.evaluators = users.map((x) => x.id);
    },
    fetchEvaluationSituations() {
      this.$restful.Get(`/teaching/evaluation-situation/`).then((response) => {
        this.evaluation_situations = response;
      });
    },
    fetchEvaluationsInfo() {
      if (this.instrument.test_type_default != null) {
        this.$restful
          .Get(
            `/evaluations2/test-type-default/${this.instrument.test_type_default}/`
          )
          .then((response) => {
            this.instrument_type = response;
          });
      }
      if (this.instrument.test_users_default != null) {
        this.$restful
          .Get(
            `/evaluations2/test-users-default/${this.instrument.test_users_default}/`
          )
          .then((response) => {
            this.instrument_users = response;
            this.evaluators = response.evaluators;
            this.evaluatees = response.evaluatees;
          });
      }
    },
    fetchMatterEvaluation(evaluation) {
      if (evaluation && evaluation.matter_evaluation)
        this.$store
          .dispatch(names.FETCH_MATTER_EVALUATION, evaluation.matter_evaluation)
          .then((response) => {
            this.matt_pro_eva_evaluative_agents = response.evaluative_agents;
            this.matt_pro_eva_evaluation_situations =
              response.evaluation_situations;
          });
      if (evaluation && evaluation.egress_profile_matter_evaluation) {
        this.$restful
          .Get(
            `/teaching/egress-profile-matter-evaluation/${evaluation.egress_profile_matter_evaluation}/`
          )
          .then((response) => {
            this.matt_pro_eva_evaluative_agents = response.evaluative_agents;
            this.matt_pro_eva_evaluation_situations =
              response.evaluation_situations;
          });
      }
    },
    saveInstrument() {
      this.$v.validationGroup.$touch();
      if (this.$v.validationGroup.$anyError) {
        return;
      }
      this.saved = true;

      const totalDays =
        parseFloat(this.duration_time.weeks_time) * 7 +
        parseFloat(this.duration_time.days_time);
      const formattedHours = this.duration_time.hours_time
        .toString()
        .padStart(2, "0");
      const formattedMinutes = this.duration_time.minutes_time
        .toString()
        .padStart(2, "0");

      this.instrument.time = `${totalDays} ${formattedHours}:${formattedMinutes}:00`;

      if (
        !this.instrument.allow_group_creation &&
        this.newTestGroups.length > 0
      ) {
        this.$swal({
          title: `<p>¿Está seguro de que desea guardar el ${this.$getVisibleNames(
            "evaluations2.test",
            false,
            "Instrumento"
          )} siendo que existen <b>Grupos</b> ya creados?</p>`,
          text: "¡Esta acción no se podrá revertir y los grupos se eliminarán!",
          type: "warning",
          showCancelButton: true,
        }).then((result) => {
          if (result.value) {
            this.deleteGroupFromModal();
            if (isNaN(this.instrument.id)) this.createInstrument();
            else this.updateInstrument();
          } else {
            this.step = 1;
          }
        });
      } else {
        if (isNaN(this.instrument.id)) this.createInstrument();
        else this.updateInstrument();
      }
    },
    async deleteGroupFromModal() {
      for await (let student_group of this.newTestGroups) {
        this.$store.dispatch(names.DELETE_NEW_TEST_GROUP, student_group.id);
      }
    },
    createInstrument() {
      this.$store
        .dispatch(names.POST_NEW_TEST, this.instrument)
        .then((response) => {
          this.instrument = response;
          this.instrument_type.test = response.id;
          this.instrument_users.test = response.id;
          toast(
            "Se creó el " +
              this.$getVisibleNames("evaluations2.test", false, "Instrumento")
          );
          if (response.test_type_default == null) this.createInstrumentType();
          else this.updateInstrumentType();
        });
    },
    updateInstrument() {
      this.$store
        .dispatch(names.UPDATE_NEW_TEST, this.instrument)
        .then((response) => {
          this.instrument = response;
          this.instrument_type.test = response.id;
          this.instrument_users.test = response.id;
          toast(
            "Se editó el " +
              this.$getVisibleNames("evaluations2.test", false, "Instrumento")
          );
          if (response.test_type_default == null) this.createInstrumentType();
          else this.updateInstrumentType();
        });
    },
    createInstrumentType() {
      this.$restful
        .Post("/evaluations2/test-type-default/", this.instrument_type)
        .then((response) => {
          this.$store.commit(
            "MUTATE_CHANGE_NEW_TEST_TYPES_BY_NEW_TEST",
            response
          );
          this.instrument_type = response;
          if (this.instrument.test_users_default == null)
            this.createInstrumentUsers();
          else this.updateInstrumentUsers();
        });
    },
    updateInstrumentType() {
      this.$restful
        .Put(
          `/evaluations2/test-type-default/${this.instrument_type.id}/`,
          this.instrument_type
        )
        .then((response) => {
          this.$store.commit(
            "MUTATE_CHANGE_NEW_TEST_TYPES_BY_NEW_TEST",
            response
          );
          this.instrument_type = response;
          if (this.instrument.test_users_default == null)
            this.createInstrumentUsers();
          else this.updateInstrumentUsers();
        });
    },
    createInstrumentUsers() {
      this.$restful
        .Post("/evaluations2/test-users-default/", this.instrument_users)
        .then((response) => {
          this.$store.dispatch(
            "MUTATE_CHANGE_NEW_INSTRUMENT_USERS_BY_NEW_EVALUATION",
            response
          );
          this.instrument_users = response;
          this.$emit("closeModal", this.instrument.id);
        });
    },
    updateInstrumentUsers() {
      this.$restful
        .Put(
          `/evaluations2/test-users-default/${this.instrument_users.id}/`,
          this.instrument_users
        )
        .then((response) => {
          this.$store.dispatch(
            "MUTATE_CHANGE_NEW_INSTRUMENT_USERS_BY_NEW_EVALUATION",
            response
          );
          this.instrument_users = response;
          this.$emit("closeModal", this.instrument.id);
        });
    },
    deleteInstrument() {
      if (!isNaN(this.instrument.id))
        this.$swal({
          title: `<p>¿Está seguro de que desea eliminar el <b>${this.$getVisibleNames(
            "evaluations2.test",
            false,
            "Instrumento"
          )}</b>?</p>`,
          text: "¡Esta acción no se podrá revertir!",
          type: "warning",
          showCancelButton: true,
        }).then((result) => {
          if (result.value) {
            this.$store.dispatch(names.DELETE_NEW_TEST, this.instrument.id);
            toast(
              "Se eliminó el " +
                this.$getVisibleNames("evaluations2.test", false, "Instrumento")
            );
            this.$emit("closeModal");
          }
        });
    },
  },
  watch: {
    "$v.instrument_type.evaluative_agent.$model"() {
      const evaluativeAgent = this.evaluativeAgent;
      if (
        evaluativeAgent &&
        (evaluativeAgent.internal_use_id ==
          "duoc_autoevaluacion_evaluative_agent" ||
          this.$equals(evaluativeAgent.name, "Autoevaluación"))
      ) {
        this.instrument.allow_group_creation = false;
        if (this.institution && this.institution.internal_use_id == "duoc_uc") {
          this.instrument.student_can_auto_evaluate = true;
        }
      }
    },
    start_date() {
      this.setStartDate();
    },
    start_time() {
      this.setStartDate();
    },
    end_date() {
      this.setEndDate();
    },
    end_time() {
      this.setEndDate();
    },
    instrument: {
      handler() {
        if (isNaN(this.instrument.id) && this.finished_mounted)
          this.$emit("change_evaluation_form");
      },
      deep: true,
    },
  },
  created() {
    this.$store
      .dispatch(names.FETCH_NEW_EVALUATION, this.evaluation_id)
      .then((response) => {
        if (response.parent_evaluation != null) {
          this.$store
            .dispatch(names.FETCH_NEW_EVALUATION, response.parent_evaluation)
            .then((evaluation_parent) => {
              this.fetchMatterEvaluation(evaluation_parent);
            });
        } else this.fetchMatterEvaluation(response);
      });
    if (isNaN(this.instrument.id) && this.section != null) {
      this.instrument_users.evaluatees = this.section.students;
      this.instrument_users.evaluators = this.section.professors;
      this.evaluators = this.section.professors;
      this.evaluatees = this.section.students;
    }
    this.$store.dispatch(names.FETCH_ROLES);
    this.fetchEvaluationSituations();
    if (!isNaN(this.instrument.id) && this.instrument.time) {
      try {
        // Separar la cadena de tiempo en días, horas, minutos y segundos
        const timeParts = this.instrument.time.split(" ");
        const days = parseInt(timeParts.length == 2 ? timeParts[0] : 0);
        const time =
          timeParts.length == 2
            ? timeParts[1].split(":")
            : timeParts[0].split(":");
        const hours = parseInt(time[0]);
        const minutes = parseInt(time[1]);

        // Calcular semanas y días
        const weeks = Math.floor(days / 7);
        const remainingDays = days % 7;

        // Asignar los valores a duration_time directamente
        this.duration_time.weeks_time = weeks;
        this.duration_time.days_time = remainingDays;
        this.duration_time.hours_time = hours;
        this.duration_time.minutes_time = minutes;
      } catch {
        this.duration_time.weeks_time = 0;
        this.duration_time.days_time = 0;
        this.duration_time.hours_time = 0;
        this.duration_time.minutes_time = 0;
      }
    }
    this.fetchEvaluationsInfo();
    if (!this.show_step) {
      if (this.Instrument.start_time == null) {
        this.start_date = null;
        this.start_time = null;
      } else {
        try {
          const start_date = this.$moment(this.Instrument.start_time);
          this.start_date = start_date.format("YYYY-MM-DD");
          this.start_time = start_date.format("HH:mm:ss");
        } catch {
          this.start_date = this.$moment().format("YYYY-MM-DD");
          this.start_time = this.$moment().format("HH:mm:ss");
        }
      }
      if (this.Instrument.end_time == null) {
        this.end_date = null;
        this.end_time = null;
      } else {
        try {
          const end_date = this.$moment(this.Instrument.end_time);
          this.end_date = end_date.format("YYYY-MM-DD");
          this.end_time = end_date.format("HH:mm:ss");
        } catch {
          this.end_date = this.$moment().format("YYYY-MM-DD");
          this.end_time = this.$moment().add(2, "hours").format("HH:mm:ss");
        }
      }
    }
    setInterval(() => {
      this.finished_mounted = true;
      clearInterval();
    }, 500);
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
.block-title-container {
  display: flex;
  justify-content: space-between;
}
.row-title-duration,
.row-duration,
.input-duration-times {
  text-align: center;
}
label {
  margin: 0 !important;
}
.btn-clear {
  max-height: 31px;
}
.end-time-input,
.start-time-input {
  margin-right: 35px;
}
.weighing-aviable {
  font-size: 10pt;
}
.colorup-weighing-available {
  color: #28a745;
}
.colordown-weighing-available {
  color: red;
}
.step-grid {
  display: flex;
  justify-content: center;
  margin-bottom: 10% !important;
}
.open-file-selector {
  width: 100%;
}
.step-icon-border {
  width: 50px;
  height: 50px;
  border: 3px solid var(--secondary-color);
  border-radius: 50%;
  padding: 11px 1px 1px 12px;
  z-index: 30;
  background: #fff;
}
.first-load-border {
  background: white;
  border: 3px solid #28a745;
}
.second-load-border {
  border: 3px solid #28a745;
  background: white;
}
.third-load-border {
  background: white;
}
.second-load-border-step1 {
  background: #28a745;
}
.third-load-border-step1 {
  background: #28a745;
}
.third-load-border-step2 {
  background: #28a745;
}
.first-load-icon {
  color: var(--secondary-color);
}
.second-load-icon {
  color: var(--secondary-color);
}
.third-load-icon {
  color: var(--secondary-color);
}
.second-load-icon-step1 {
  color: white !important;
}
.third-load-icon-step1 {
  color: white !important;
}
.third-load-icon-step2 {
  color: white !important;
}
.step-icon {
  font-size: 22px;
  color: var(--secondary-color);
}
.step-icon-info {
  font-size: 22px;
}
.step-caption {
  width: 170px;
  text-align: center;
  margin-top: 1.3em;
  transform: translateX(-42%);
}
.progress {
  border: 1px solid var(--secondary-color);
  height: 10px;
  margin-top: -12.9%;
  margin-bottom: 5%;
}
.first-load {
  border: 3px solid #28a745;
  background: #28a745;
  width: 35%;
  margin-right: 20%;
}
.second-load {
  border: 3px solid #28a745;
  background: #28a745;
  width: 50%;
  margin-right: 20%;
}
.third-load {
  border: 3px solid #28a745;
  background: #28a745;
  width: 65%;
  margin-right: 20%;
}
.fill {
  width: 100%;
}
.container-group {
  margin-top: 12%;
}
.hidden-step {
  display: none;
}
.is-shown {
  display: block;
}
.done {
  border: 2px solid #28a745 !important;
  background: #28a745;
  transition: 0.4s;
}
.info {
  border: 3px solid #28a745 !important;
  background: white;
  transition: 0.4s;
}
.instrument-form-description-locked {
  width: calc(100%);
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.875rem;
  min-height: calc(1.5em + 0.5rem + 2px);
  height: auto;
  max-height: 200px;
  overflow: auto;
  padding: 0.25rem 1.2rem;
  line-height: 1.5;
  background-color: #e9ecef;
  opacity: 1;
}
.instrument-form-description {
  width: calc(100%);
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.875rem;
  min-height: calc(1.5em + 0.5rem + 2px);
  height: auto;
  max-height: 200px;
  overflow: auto;
  padding: 0.25rem 1.2rem;
  line-height: 1.5;
}
.instrument-form-description >>> p {
  margin-bottom: 0.7rem !important;
}
</style>

